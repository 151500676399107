.swiper {
    width: 100%;
    height: 100%;
  }

  .carousel-wrapper {
    margin-bottom: 20px;
  }

 /* .mySwiper {
    height: 400px;
 } */
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  .reservation-wrapper {
    background-color: var(--ion-color-warning);
    padding: 20px;
  }

  #header {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 10px;
  }


  ion-item {
    margin-bottom: 5px !important;
  }

  .amenities-header {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 10px;
  }
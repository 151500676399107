ion-card .header-photo {
  object-fit: cover;
  height: 200px;
  width: 100%;
}

.swiper {
  width: 100%;
  height: 200px;
}

.carousel-wrapper {
  margin-bottom: 20px;
}
ion-card {
  /* display: flex;
  flex-wrap: wrap;
  align-content: center; */
  max-width: 1000px;
  margin: 0 auto;
  margin: 20px;
}

ion-item.main-item {
  border-color: var(--ion-color-dark);
  border-style: solid;
  border-width: 1px;
  border-radius: 0px;
  margin-bottom: 10px;
}


div.booking-search-container {
    background-color: var(--ion-color-primary);
    padding: 20px 0;
    border-top: 1px solid var(--ion-color-dark);

    @media(min-width: 768px) {
        padding: 60px;
    }
}

div.header {
    font-size: 1.3em;
    font-weight: 600;
    text-align: center;
    margin: 15px;
  
}

ion-text {
  display: block;
}
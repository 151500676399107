

ion-grid.page-wrapper {
    max-width: 1000px;
    margin: 0 auto;
}

ion-segment {
    max-width: 600px;
    margin: 0 auto;
}

h3.no-stays {
    text-align: center;
}
ion-card {
  border: 2px solid var(--ion-color-dark);
  box-shadow: none;
  border-radius: 0;
}

ion-card > ion-card-content {
  margin-top: 15px;
}

ion-card > ion-card-header.primary {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-dark);
}

ion-card > ion-card-header.secondary {
  background-color: var(--ion-color-secondary);
}

ion-card > ion-card-header.success {
  background-color: var(--ion-color-success);
}

ion-card > ion-card-header.warning {
  background-color: var(--ion-color-warning);
}

.picker-opt.picker-opt-selected {
    color: var(--ion-color-warning) !important;
}

.swiper-pagination-bullet {
  background: var(--ion-color-primary-shade) !important;
}

.swiper-pagination-bullet-active {
  background: var(--ion-color-primary)  !important;
}

ion-button > ion-spinner {
  margin-left: 20px;
}